import { render, staticRenderFns } from "./LogsTableSkeletonItem.vue?vue&type=template&id=11428f48&scoped=true&"
import script from "./LogsTableSkeletonItem.vue?vue&type=script&lang=js&"
export * from "./LogsTableSkeletonItem.vue?vue&type=script&lang=js&"
import style0 from "./LogsTableSkeletonItem.vue?vue&type=style&index=0&id=11428f48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11428f48",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
